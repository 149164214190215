<div class="Content2Column">
  <ul id="DiaryListing">
    <li (click)="navigateToEventDetails(event)" *ngFor="let event of eventsList | paginate: config">
      <a href="#">
        <img src="{{domainUrl+pathData.about+event.imgUrl}}" alt="">
      </a>
      <div class="mix-date">
        <p><span>{{event.startDate|date:"dd"}}</span> {{event.startDate|date:"MMM yyyy"}}</p> <span class="to">to</span>
        <p><span>{{event.endDate|date:"dd"}}</span> {{event.endDate|date:"MMM yyyy"}}</p>
      </div>
      <div class="diary-title">
        <a href="#">
          <div [innerHTML]="event.text"></div>
        </a>
      </div>
    </li>
  </ul>
  <pagination-controls [responsive]=true previousLabel="{{'previos'|translate}}" nextLabel="{{'next'|translate}}"
    (pageChange)="pageChange($event)">
  </pagination-controls>
</div>

<!--side menue-->
<div class="ColumnLeft">
  <nav id="SideHeadingPages" class="SideHeading">
    <h2>{{'RelatedPages'|translate}}</h2>
    <ul class="SideList">
      <li><a style="text-align: center;" routerLink="" id="nav1125">{{'home'|translate}}</a></li>
      <li><a style="text-align: center;" routerLink="/about" id="nav1126">{{'about'|translate}}</a></li>
      <li><a style="text-align: center;" routerLink="/news" id="nav1127">{{'news'|translate}}</a> </li>
      <li><a style="text-align: center;" routerLink="/events" id="nav1127">{{'events'|translate}}</a> </li>
      <li><a style="text-align: center;" routerLink="/apply" id="nav1127">{{'applynow'|translate}}</a> </li>
    </ul>
  </nav>
</div>
