import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './layout/main-page/main-page.component';
import { AboutComponent } from './pages/about/about.component';
import { ApplyNowComponent } from './pages/apply-now/apply-now.component';
import { EventDetailsComponent } from './pages/events/event-details/event-details.component';
import { EventsComponent } from './pages/events/events.component';
import { HomeComponent } from './pages/home/home.component';
import { NewDetailsComponent } from './pages/news/new-details/new-details/new-details.component';
import { NewsComponent } from './pages/news/news.component';
import { RedirectComponent } from './redirect/redirect.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  {
    path: "", component: MainPageComponent, children: [
      { path: "events", component: EventsComponent },
      { path: "events/:id", component: EventDetailsComponent },
      { path: "news", component: NewsComponent },
      { path: "apply", component: ApplyNowComponent },
      { path: "about", component: AboutComponent },
      { path: "admin", component: RedirectComponent },
      { path: "newDetails/:newId", component: NewDetailsComponent }
    ]
  },];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

