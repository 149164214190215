

<div class="col-md-8 col-md-offset-2 col-xs-12 Content2Column">
  <div class="mainheading">

    <!-- Begin Top Meta -->
    <div class="row post-top-meta">
      <div class="col-md-2">
      </div>
      <div class="col-md-10">
        <span class="post-date">{{newData.DatetimeOfPublish|date:"dd/MM/yyyy"}}</span>
      </div>
    </div>
    <!-- End Top Menta -->

    <h1 class="posttitle"> {{newData.Title}} </h1>

  </div>

  <!-- Begin Featured Image -->
  <img src="{{domainUrl+newData.ImageUrl}}" class="featured-image img-fluid" alt="">
  <!-- End Featured Image -->

  <!-- Begin Post Content -->
  <div class="article-post">
    <p>
      {{newData.SubTitle}}    </p>
   
  </div>
  <!-- End Post Content -->

  

</div>








                    <!--side menue-->
                    <div class="ColumnLeft">
                      <nav id="SideHeadingPages" class="SideHeading">
                        <h2>{{'RelatedPages'|translate}}</h2>
                        <ul class="SideList">
                          <li><a style="text-align: center;" routerLink="" id="nav1125">{{'home'|translate}}</a></li>
                          <li><a style="text-align: center;" routerLink="/about" id="nav1126">{{'about'|translate}}</a></li>
                          <li><a style="text-align: center;" routerLink="/news" id="nav1127">{{'news'|translate}}</a> </li>
                          <li><a style="text-align: center;" routerLink="/events" id="nav1127">{{'events'|translate}}</a> </li>
                          <li><a style="text-align: center;" routerLink="/apply" id="nav1127">{{'applynow'|translate}}</a> </li>
                        </ul>
                      </nav>
                    </div>
  

