import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReadingSettingsDataService } from './reading-settings-data.service';

@Injectable({
  providedIn: 'root'
})
export class GradesService {
  constructor(private httpClient: HttpClient, private readingSettingsData: ReadingSettingsDataService) { }

  getGrades(stageId: number) {
    return this.httpClient.get(this.readingSettingsData.getSchoolDomain() + "/website/grades/getGrades?stageId=" + stageId);
  }
  getAllGrades(){
    return this.httpClient.get(this.readingSettingsData.getSchoolDomain() + "/website/grades/getAllGrades");


  }
}
