<nav id="navholder">
  <ul id="dmenu" class="nav">
    <!-- <div class="menu-btn">
      <span></span>
      <span></span>
      <span></span>
    </div> -->
    <li><a routerLink="" id="nav1125">{{'home'|translate}}</a></li>
    <li><a routerLink="/about" id="nav1126">{{'about'|translate}}</a></li>
    <li><a routerLink="/news" id="nav1127">{{'news'|translate}}</a> </li>
    <li><a routerLink="/events" id="nav1127">{{'events'|translate}}</a> </li>
    <li><a routerLink="/apply" id="nav1127">{{'applynow'|translate}}</a> </li>
  </ul>
</nav>
