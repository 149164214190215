import { Component, OnInit } from '@angular/core';
import { LoadScriptsService } from 'src/app/core/globalFunctions/load-scripts.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartmentsService } from 'src/app/core/services/commonServices/departments.service';
import { StagesService } from 'src/app/core/services/commonServices/stages.service';
import { GradesService } from 'src/app/core/services/commonServices/grades.service';
import { DevisionsService } from 'src/app/core/services/commonServices/devisions.service';
import { keyValue } from 'src/app/core/models/keyValue';
import { ReligionsService } from 'src/app/core/services/commonServices/religions.service';
import { NationalitiesService } from 'src/app/core/services/commonServices/nationalities.service';
import { StudentApplyService } from 'src/app/core/services/student-apply.service';
import { nullFieldsValidator } from 'src/app/core/globalFunctions/validators/nullFieldsValidator';
import { devisionValidator } from './ControllerValidators/devisionValidator';
import { gardiansValidator } from './ControllerValidators/gardiansValidator';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/commonServices/language.Service';
import { SocialStatusService } from 'src/app/core/services/commonServices/socialStatus.service';
import { DeclarationEnrollmentService } from 'src/app/core/services/commonServices/DeclarationEnrollment.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-apply-now',
  templateUrl: './apply-now.component.html',
  styleUrls: ['./apply-now.component.css']
})
export class ApplyNowComponent implements OnInit {

  constructor(private loadPageScripts: LoadScriptsService, private formBuilder: FormBuilder,
    private departmentsService: DepartmentsService, private stagesService: StagesService, private gradesService: GradesService,
    private devisionsService: DevisionsService, private ReligionsService: ReligionsService, private nationalitiesService: NationalitiesService,
    private studentApply: StudentApplyService, private router: Router, private translate: TranslateService,private LanguageService:LanguageService,
    private SocialStatusService:SocialStatusService,private DeclarationEnrollmentService:DeclarationEnrollmentService) {
    loadPageScripts.applyNowScripts();
    this.fillDepartments();
    this.fillStages();
    this.fillNationalities();
    this.fillReligion();
    this.fillSecondLanguage();
    this.fillSocialStatus();
    this.fillDeclarationEnrollment();
    this.fillAllGrades();
  }

  departments: keyValue[] = [];
  stages: keyValue[] = [];
  grades: keyValue[] = [];
  devisions: keyValue[] = [];
  relegions: keyValue[] = [];
  nationalities: keyValue[] = [];
  languages: keyValue[] = [];
  socialStauts:keyValue[] = [];
  DeclarationEnrollment:string='';
  birthDateCertificate: any;
  fatherPassport: any;
  motherPassport: any;
  studentPassport: any;
  IsHaveBrothers:boolean=false;
  allgrades: keyValue[] = [];
showPrint:boolean=false;
  isFormSubmited = false;

  formData = this.formBuilder.group({
    studentData: this.formBuilder.group({
      name: ['', Validators.required],
      nameEngilsh: ['', Validators.required],
      gender: ['male'],
      department: [null, nullFieldsValidator()],
      stage: [null, nullFieldsValidator()],
      grade: [null, nullFieldsValidator()],
      devision: [null, devisionValidator(this.devisions)],
      nationalId: [, Validators.required],
      birthDate: [, Validators.required],
      homeAddress: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      nationality: [null, nullFieldsValidator()],
      religion: [null, nullFieldsValidator()],
      secondLanguage: [''],
      lastSchool:[''],
      relativesNumber:[''],
      stuBirthDateCertificate:['', nullFieldsValidator()],
      fatherPassport:['', nullFieldsValidator()],
      motherPassport:['', nullFieldsValidator()],
      studentPassport:['', nullFieldsValidator()],
      IsHaveBrothers:[false],
      brotherslit:this.formBuilder.array([])

    }),
    fatherData: this.formBuilder.group({
      name: ['', Validators.required],
      nameEngilsh: [''],
      phoneNumber: ['', Validators.required],
      email: [''],
      nationalId: [, Validators.required],
      fathernationality: [''],
      fatherjob: [''],
      fatherCompany: ['']
    }),
    motherData: this.formBuilder.group({
      name: ['', Validators.required],
      nameEngilsh: [''],
      phoneNumber: ['', Validators.required],
      email: [''],
      nationalId: [, Validators.required],
      mothernationality: [''],
      motherjob: [''],
      motherSocialStatus:[''],
      legalGardian:[1]

    }),
    gardian: this.formBuilder.group({
      gardianType: ["father"],
      name: [''],
      nameEngilsh: [''],
      phoneNumber: [''],
      email: [''],
      nationalId: [''],
      relationWithStudent: [''],
      parentnationality:[''],
      parentjob:['']
    }, { Validators: [gardiansValidator()] }),
    Enrollment:this.formBuilder.group({
      IsAcceptedEnrollment:[false, Validators.required],
    }),
    MedicalData:this.formBuilder.group({
      personName:[''],
      EmergencyMobile:[''],
      EmergencyEmail:[''],
      emergencyAddress:[''],
      previousMedicalStatus:[''],
      IsStudentTakeDrugs:['no'],
      DrugsName:[''],
      SchoolResponsibilty:[''],
      FamilyDoctorRecommendations:[''],
      StudentDoActivities:['yes'],
      StudentRetardationType:[1],
      studentOrder:[1],


    })

  
  }
  );


  get studentDatacontrols(){
    return this.formData.controls.studentData as FormGroup
   }

  get layerscontrols(){
    return this.studentDatacontrols.controls
   }

   get brotherlistcontrols(): FormArray{
    return this.layerscontrols.brotherslit as FormArray;
   }

   addlayer(){
    let empty:boolean=false
    const control=<FormArray>this.layerscontrols.brotherslit as FormArray    
    if(control.controls.length > 0){
      for (let index = 0; index < control.controls.length; index++) {
        if(control.controls[index].value.name =='' || control.controls[index].value.grad ==''||control.controls[index].value.grad==null){
           empty=true;
           
           break
        }
      }
      if(!empty){
        control.push(this.patchValues('',null))
      }

  }
  else{
    control.push(this.patchValues('',null))
  }
   
   }
   patchValues(name:any,grad:any){
    return this.formBuilder.group({
      name: [name,Validators.required],
      grad: [grad,Validators.required],
    })

}

  fillDepartments(): void {
    this.departmentsService.getDepartments().subscribe((data: any) => {
      this.departments = data;

    });
  }

  fillStages(): void {
    this.stagesService.getStages().subscribe((data: any) => {
      this.stages = data;
    });;
  }

  fillGrades(stageid: number): void {
    this.grades = [];
    if (stageid != null) {
      this.gradesService.getGrades(stageid).subscribe((data: any) => {
        this.grades = data;
        this.filldevision(this.grades[0]?.key);
      });
    }
  }
  fillAllGrades():void{
    this.gradesService.getAllGrades().subscribe((data: any) => {
      this.allgrades = data;
    })

  }
  filldevision(gradeid: number): void {
    this.devisions = [];
    if (gradeid != null) {
      this.devisionsService.getDevisions(gradeid).subscribe((data: any) => {
        this.devisions = data;
      });
    }
  }
  fillReligion() {

    this.ReligionsService.getReligions().subscribe((data: any) => {
      this.relegions = data;
    })
  }
  fillNationalities() {
    this.nationalitiesService.getNationalities().subscribe((data: any) => {
      this.nationalities = data;
    })
  }
  fillSecondLanguage() {
    this.LanguageService.getLanguages().subscribe((data: any) => {
      this.languages = data;
    })
  }
  fillSocialStatus(){
this.SocialStatusService.getSocialStatus().subscribe((data: any) => {
  this.socialStauts = data;
})

  }
  fillDeclarationEnrollment(){
    this.DeclarationEnrollmentService.getDeclarationEnrollment().subscribe((data: any) => {
      this.DeclarationEnrollment = data==null?"":data.text;
    
    })
  }
  stageChangeEvent() {
    this.fillGrades(this.formData.get(['studentData', 'stage'])?.value);
  }
  gradeChangeEvent() {
    this.filldevision(this.formData.get(['studentData', 'devision'])?.value);
  }
  handleFileInput(event:any,type:any) {

    if (event.target.files.length > 0) {
      if(type=="birthdate"){
      this.birthDateCertificate =event.target.files[0];   
      }  
      if(type=="studentPassport"){
        this.studentPassport =event.target.files[0];   
        }  
        if(type=="fatherPassport"){
          this.fatherPassport =event.target.files[0];   
          }  
          else{
            this.motherPassport =event.target.files[0];   


          }
    }      
}
handleBrothers(event:any){
this.IsHaveBrothers=event.target.checked;


}
printData(){}
  sendData() {
    this.isFormSubmited = true;
    debugger;
    if (!this.formData.valid) {
      Swal.fire(this.translate.instant('payAttention'), this.translate.instant('dataRequired'), 'warning');
      return;
    }
    this.studentApply.sendData(this.formData,this.birthDateCertificate,this.motherPassport,this.fatherPassport,this.studentPassport).subscribe((data: any) => {
      Swal.fire(this.translate.instant('goodJob'), this.translate.instant('appliedsuccessfully'), 'success');
      this.isFormSubmited = false;
      this.reload();
    }, (error: any) => {
      console.log(error);
      Swal.fire(this.translate.instant('payAttention'), this.translate.instant(error.error), 'error');
    })
  }

  reload() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  ngOnInit(): void {
  }

}
